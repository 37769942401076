@import url('https://use.typekit.net/nbj3eyo.css');
* {
    font-family: "Circe", sans-serif;
}

.StripeElement {
    background-color: #d0d0dc;
    padding: 0.5rem;
    border: 2px solid #a3a3a3;
    border-radius: 0.5rem;
    transition-duration: 150ms;
    transition-property: all;
}

.StripeElement--focus {
    border: 2px solid #ea580c;
}

.StripeElement--webkit-autofill {
    background-color: #d0d0dc;
}

.StripeElement--invalid {
    border-color: #ef4444;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
